.goalSettings {
  padding: 23px 0 37px;
}

.analyticsFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  .averageTypeSwitch {
    display: flex;
    align-items: center;
    margin-left: 8px;
    p {
      font-weight: 500;
    }
  }
}
