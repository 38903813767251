@import "../../assets/scss/mixins";

$legendWidth: 200px;

.chartWrapper {
  @include tableWrapper;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  margin-left: 30px;
  overflow: visible;
  &:first-of-type {
    margin-left: 0;
  }
  &.noSelector {
    .chart {
      padding-top: 0;
    }
  }

  canvas {
    height: 100%;
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    margin-top: -5px;

    .headerText {
      color: #000000;
      font-weight: 500;
    }
  }

  .chartWrap {
    display: flex;
    height: 100%;
    &.withoutShadow {
      .chartLegend {
        &::before {
          display: none;
        }
      }
    }

    .chart {
      height: 100%;
      width: calc(100% - #{$legendWidth});
      padding-top: 10px;
    }

    .chartLegend {
      position: relative;
      width: $legendWidth;
      &::before {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        z-index: 1;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #fff);
        pointer-events: none;
      }
      &.noScroll {
        > ul {
          overflow: hidden;
        }
      }
    }
  }
}
