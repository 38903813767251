@import "../../assets/scss/editors";

.editor {
  .formWrapper {
    .form {
      .img {
        display: flex;
        padding: 16px 0 0;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .headerBtn {
    background-color: $blueColor;
    height: 37px;
    border-radius: 18.5px;
    min-width: 174px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    font-size: 1.143rem;
  }
}


