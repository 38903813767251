@import "variables";

@mixin tableWrapper {
  background-color: #fff;
  border: 1px solid #D1D1D1;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 30px;
  padding: 20px $tableRightPadding 20px 30px;
  overflow: hidden;
}

@mixin useBlur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

@mixin alignFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hideTheScrollbar {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: 0;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

@mixin saveBtnStyles {
  display: block;
  width: 100%;
  max-width: 127px;
  height: 37px;
  background-color: $yellowColor;
  border-radius: 18.5px;
  text-transform: uppercase;
  font-weight: 500;
  color: $darkColor;
  font-size: 1.143rem;
}
