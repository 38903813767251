@import "../../assets/scss/variables";

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(196, 196, 196, 0.53);
  z-index: $popupZIndex;
  padding-top: $headerHeight;
}
