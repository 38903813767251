@import "./assets/scss/variables";
@import "./assets/scss/fonts";
@import "./assets/scss/toastify";
@import "./assets/scss/select";
@import "./assets/scss/datepicker";
@import "./assets/scss/chart-legend";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: $fontSize;
}

body {
  color: $darkColor;
  font-size: $fontSize;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:focus {
  font-family: $fontFamily;
  text-decoration: none;
  color: inherit;
}
input, button {
  font-size: $fontSize;
  font-family: $fontFamily;
  box-shadow: none;
  border: 0;
  outline: 0;
  &:focus {
     outline: 0;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
}

button {
  cursor: pointer;
  box-shadow: none;
  border: 0;
}

ul, ol {
  list-style: none;
}
