@import "variables";

.chart-selector {
  width: 140px;
  &:focus {
    outline: 0;
  }

  .chart-selector__control {
    border: 0;
    min-height: auto;
  }

  .chart-selector__control--is-focused {
    box-shadow: none;
    min-height: auto;
    border: 0;
  }

  .chart-selector__indicators {
    display: none;
  }

  .chart-selector__value-container.chart-selector__value-container--has-value {
    padding: 0;
  }

  .chart-selector__single-value {
    margin: 0 0 0 10px;
    color: $blueColor;
    text-decoration: underline;
  }

  .chart-selector__menu {
    margin: 5px 0 0;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);

    .chart-selector__option {
      font-size: .929rem;
      font-weight: 300;
    }

    .chart-selector__option.chart-selector__option--is-selected {
      background-color: transparent;
      color: $blueColor;
      font-size: .929rem;
      font-weight: 500;
    }

    .chart-selector__option.chart-selector__option--is-focused {
      background-color: transparent;
      font-size: .929rem;
      font-weight: 500;
    }
  }
}

.settings-selector {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 21.5px;
  &:focus {
    outline: 0;
  }

  &.color {
    width: 100px;
  }

  .settings-selector__control {
    background-color: #F5F7F8;
    border-radius: 23.5px;
    height: 45px;
    border: 0;
    box-shadow: none;
    min-height: auto;
    &.settings-selector__control--menu-is-open {
      border-radius: 23.5px 23.5px 0 0;

      .settings-selector__indicators {
        .settings-selector__indicator.settings-selector__dropdown-indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .settings-selector__value-container {
    padding: 0 24px;
    height: 35px;
  }

  .settings-selector__placeholder {
    font-size: 1rem;
    color: #99AFC6;
  }

  .settings-selector__single-value {
    color: #000000;
    font-weight: 500;
  }

  .settings-selector__indicators {
    cursor: pointer;

    .settings-selector__indicator-separator {
      display: none;
    }

    .settings-selector__indicator.settings-selector__dropdown-indicator {
      svg {
        width: 16px;
      }
    }
  }

  .settings-selector__menu {
    margin: 0;
    border-radius: 0 0 23.5px 23.5px;
    box-shadow: none;
    overflow: hidden;

    .settings-selector__menu-list {
      padding: 0;

      .settings-selector__option {
        background-color: #F5F7F8;
        font-weight: 500;
        color: $darkColor;
        padding: 6px 24px;
        cursor: pointer;
        &:first-of-type {
          padding-top: 12px;
        }
        &:last-of-type {
          padding-bottom: 12px;
        }
        &.settings-selector__option--is-focused {
          color: #000000;
        }
      }
    }
  }
}

.filter-selector {
  &:focus {
    outline: 0;
  }

  &.color {
    width: 100px;
  }

  .filter-selector__control {
    background-color: #e1edfe;
    border-radius: 23.5px;
    height: 21px;
    border: 0;
    box-shadow: none;
    min-height: auto;
    &.filter-selector__control--menu-is-open {
      border-radius: 23.5px 23.5px 0 0;

      .filter-selector__indicators {
        .filter-selector__indicator.filter-selector__dropdown-indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .filter-selector__value-container {
    padding: 0 24px;
  }

  .filter-selector__placeholder {
    font-size: 1rem;
    color: #99AFC6;
  }

  .filter-selector__single-value {
    color: #000000;
    font-weight: 500;
  }

  .filter-selector__indicators {
    cursor: pointer;
    height: 21px;

    .filter-selector__indicator-separator {
      display: none;
    }

    .filter-selector__indicator.filter-selector__dropdown-indicator {
      svg {
        width: 16px;
      }
    }
  }

  .filter-selector__menu {
    margin: 0;
    border-radius: 0 0 23.5px 23.5px;
    border: 1px solid #aeaeae;
    overflow: hidden;

    .filter-selector__menu-list {
      padding: 0;

      .filter-selector__option {
        background-color: #F5F7F8;
        font-weight: 500;
        color: $darkColor;
        padding: 6px 24px;
        cursor: pointer;
        &:first-of-type {
          padding-top: 12px;
        }
        &:last-of-type {
          padding-bottom: 12px;
        }
        &.filter-selector__option--is-focused {
          color: #000000;
        }
      }
    }
  }
}
