@import "../../../assets/scss/variables";

.confirmation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  color: $darkColor;
  font-weight: 500;

  .confirmationBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    padding-right: 10px;
    user-select: none;

    .btn {
      border-radius: 18.5px;
      background-color: $yellowColor;
      font-weight: 500;
      text-transform: uppercase;
      color: $darkColor;
      padding: 5px 15px;
    }
  }
}
