@import "../../../assets/scss/variables";

.table {
  background-color: #fff;
  border: 1px solid #D1D1D1;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 30px;
  padding: 20px 52px 20px 34px;

  .noContent {
    padding: 100px 0;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: .27px;
    font-weight: 600;
    color: $darkColor;
    opacity: .5;
    text-align: center;
  }

  .tableRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD;
    padding: 2px 5px;
    height: 46px;
    color: #656565;
    font-weight: 500;
    transition: background-color $generalAnimationDuration;
    cursor: pointer;
    &:hover:not(.tableHeader) {
      background-color: rgba(68, 68, 68, .1);
    }
    &:last-of-type {
      border-bottom: 0;
    }
    &.tableHeader {
      font-weight: 300;
      padding: 0 5px 1px;
      height: 33px;
      cursor: default;
      user-select: none;
    }
  }

  .tableItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    &:first-child {
      text-align: left;
      padding-left: 0;
    }
    &:last-child {
      text-align: right;
      padding-right: 0;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
