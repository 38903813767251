@import "../../assets/scss/not-auth";

.forgotPassNote {
  font-style: italic;
  text-align: center;
  color: #fff;
  margin-bottom: 15px;
}

.notAuth {
  .notAuthForm {
    .notAuthFormSubmit {
      margin: 48px auto 0;
    }
  }
}
