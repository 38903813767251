@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/tooltip";

.monthlyTable {
    position: relative;
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-top: none;
    border-radius: 0 0 30px 30px;
    padding: 20px $tableRightPadding 20px 30px;
    overflow: hidden;

    .column {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tablesColor;
        width: calc((100% - 110px) / 12);
        flex-shrink: 0;
        padding: 0 10px;

        &:first-of-type {
            justify-content: center;
        }
    }

    .monthlyItem {
        padding: 10px 0 !important;
        input {
            width: 65px !important;
        }
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header {
            padding: 10px 0;
            background-color: #E6F6FF;
        }
        .headerItem {
            color: #000000;
            line-height: 16px;
            font-weight: bold;
            text-align: center;
            flex-direction: column;
        }
    }

    .goalInput {
        width: 88px;
        height: 35px;
        text-align: center;
        background-color: #d1d1d1a8;
        border-radius: 23.5px;
      }
}