@import "../../../assets/scss/mixins";
@import "../../../assets/scss/icons";

.goalContent {
  @include tableWrapper;

  .goalTable {
    .goalTableHeader {
      height: 48px;
      background-color: #E6F6FF;
      display: flex;
      align-items: center;

      .tableColumn {
        position: relative;
        font-weight: 300;
        color: #656565;

        &.tableColumn1 {
          padding: 0 10px;
        }

        &.tableColumn4 {
          display: flex;
          justify-content: center;
        }
      }
    }

    .collapseBtn {
      width: 15px;
      height: 15px;
      background-color: $darkColor;

      &.open {
        mask: url("../../../assets/imgs/arrow-up.svg") no-repeat center;
      }

      &.close {
        mask: url("../../../assets/imgs/arrow_down.svg") no-repeat center;
      }
    }

    .goalTableBody {
      font-weight: 400;
      color: $tablesColor;

      .goalTableItem {
        position: relative;
        min-height: 60px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        background-color: #fff;
        &:not(:last-child) {
          border-bottom: 1px solid #D1D1D1;
        }
        &:nth-child(odd) {
          background: #FFFDF5;
        }
  
        .tableColumn {
          position: relative;
          &.tableColumn2 {
            text-align: left;
          }
        }

        .ReactCollapse--collapse {
          position: absolute;
          width: 100%;
        }

        .goalInput {
          width: 88px;
          height: 35px;
          text-align: center;
          background-color: #F5F7F8;
          border-radius: 23.5px;
        }
      }

      .goalTableItemHighlight {
        background-color: #cefad0 !important;
      }
    }

    .tableColumn1 {
      width: 60px;
    }

    .tableColumn2 {
      &.tableValueColumn2 {
        width: calc(100% - 220px);
      }
      width: calc(100% - 320px);
      text-align: center;
    }

    .tableColumn3 {
      width: 140px;
      text-align: center;

      .goalInput {
        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .tableColumn4 {
      width: 140px;
      text-align: center;
    }
  }

  .wrapper {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
  }

  .monthlyTable {
    display: flex;
    width: 100%;
  }

  .goalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .footerDate {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      max-width: 117px;
      background-color: $yellowColor;
      background-image: url("../../../assets/imgs/calendar__icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 9px) 50%;
      height: 23px;
      border-radius: 29px;
      padding: 3px 12px;
      font-size: 0.929rem;
      color: $darkColor;
      box-shadow: none;
      border: 0;
      cursor: pointer;
    }

    .formBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #fff;
      // color: $blueColor;
      border: 1px solid $blueColor;
      border-radius: 8px;
      // height: 37px;
      // min-width: 180px;
      // padding: 5px 10px;
      // text-transform: uppercase;
      // font-size: 1.143rem;
      // font-weight: 500;
      margin-left: 14px;
      &.save {
        background-color: $blueColor;
        color: #fff;

        span {
          background-color: #fff;
          display: block;
          width: 15px;
          height: 15px;
          transform: rotate(180deg);
        }
      }

      &[disabled] {
        opacity: .5;
      }

      span {
        background-color: $blueColor;
        display: block;
        width: 15px;
        height: 15px;
        transform: rotate(180deg);
      }
    }
  }
  
}
