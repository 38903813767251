.colorPicker {
  display: flex;
  align-items: center;
  background-color: #ECEFF0;
  border-radius: 4px;
  padding: 4px;

  .colorBox {
    border-radius: 4px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .noColor {
    display: flex;
    align-items: center;
    height: 20px;
    margin: 0 4px;
  }

  .imgEditIcon {
    display: block;
    width: 18px;
    height: 18px;
    background: url("../../assets/imgs/edit-color.svg") no-repeat center;
    background-size: cover;
  }

  .picker {
    position: relative;
    display: inline-block;
  }

  .colorInput {
    opacity:0; 
    position:absolute; 
    left:0;
    top:0;
    width:100%;
  }
}