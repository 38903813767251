@import "../../../../assets/scss/variables";

$paginationBtnsSize: 26px;

.pagination {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 9px;

  .button {
    width: 15px;
    height: $paginationBtnsSize;
    background: url("../../../../assets/imgs/pagination-arrow__icon.svg") no-repeat center;
    margin-right: 8px;
    &.next {
      transform: rotate(180deg);
      margin-right: 0;
      margin-left: 8px;
    }
  }

  .list {
    list-style: none;

    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: $paginationBtnsSize;
      height: $paginationBtnsSize;
      border: 1px solid $darkBlueColor;
      border-radius: 50%;
      font-size: .786rem;
      color: $darkColor;
      margin-left: 3px;
      cursor: pointer;
      font-weight: 500;
      &:first-of-type {
        margin-left: 0;
      }
      &.active {
        background-color: #D3E7F2;
      }
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $darkBlueColor;
    border-radius: 15px;
    width: 113px;
    height: $paginationBtnsSize;
    margin-left: 17px;
    user-select: none;

    .navigationTitle {
      font-size: .786rem;
    }

    .navigationField {
      border: 1px solid $darkBlueColor;
      border-radius: 4px;
      height: 19px;
      width: 27px;
      font-size: .786rem;
      text-align: center;
      margin: 0 5px 0 6px;
    }

    .navigationButton {
      background-color: #D3E7F2;
      border-radius: 4px;
      height: 19px;
      width: 23px;
      font-size: .786rem;
    }
  }
}
