@import "../../assets/scss/variables";
@import "../../assets/scss/icons.scss";

.header {
  position: relative;
  z-index: $headerZIndex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerHeight;
  background-color: $blueColor;
  box-shadow: 0 4px 7px rgba(0, 0, 0, .25);
  padding: 5px $generalPaddingRight 5px 30px;


  .headerLogo {
    overflow: hidden;
    user-select: none;
    flex-shrink: 0;

    img {
      max-width: 60px;
      height: auto;
    }
  }

  .headerInteractions {
    display: flex;
    align-items: center;
    user-select: none;

    .headerNotifications {
      position: relative;
      display: block;
      width: 29px;
      height: 29px;
      background: url("../../assets/imgs/bell_icon.svg") no-repeat center;
      background-size: cover;

      &.notifExist {
        background-image: url("../../assets/imgs/bell_yellow__icon.svg");
      }
    }

    .headerUser {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      margin-left: 10px;
      min-width: 250px;

      .headerUserSection {
        display: flex;
        align-items: center;
      }

      .headerUserContainer {
        text-align: right;
        margin: 0 19px 0 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .headerUserName {
          font-weight: 600;
          font-size: 1.286rem;
          color: #fff;
          margin-bottom: 1px;
        }

        button {
          background-color: #fff;
          width: 34px;
          height: 34px;
          border-radius: 8px;
          span {
            background-color: #F61C1C;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }

      .headerUserLogo {
        margin-left: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
