@import "../../assets/scss/variables";

$dropzoneFontColor: #99AFC6;

.dropzone {
  &.alignLeft {
    .title {
      text-align: left;
    }

    .imgArea {
      margin-bottom: 7px;
    }
  }

  .title {
    font-weight: 600;
    color: $dropzoneFontColor;
    text-align: center;
    margin-bottom: 4px;
  }
  
  .imgArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 auto 19px;
    width: 106px;
    height: 106px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $sideBarBackgroundColor;
    padding: 12px 17px;
    cursor: pointer;
    border: 3px solid $sideBarBackgroundColor;
    &.active {
      background-color: rgba(202, 202, 202, .5);
      border: 3px dashed rgba(202, 202, 202, .5);
    }
    &.preview {
      padding: 0;
      cursor: default;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    input {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    .imgAreaPlaceholder {
      text-align: center;
      font-size: .857rem;
      line-height: 14px;
      color: $dropzoneFontColor;
    }

    .imgAreaPlusIcon {
      width: 26px;
      height: 26px;
      background-color: $dropzoneFontColor;
      mask: url("../../assets/imgs/plus__icon.svg") no-repeat center;
    }
  }

  .btn {
    display: block;
    height: 20px;
    border-radius: 18.5px;
    background-color: $blueColor;
    min-width: 106px;
    margin: auto;
    color: #fff;
    text-transform: uppercase;
    font-size: .857rem;
    font-weight: 500;
    &[disabled] {
      background-color: #B9DFFF;
      pointer-events: none;
      cursor: default;
    }

    .btnIcon {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      mask: url("../../assets/imgs/bin__icon.svg") no-repeat center;
      margin-right: 11px;
    }
  }
}
