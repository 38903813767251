@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/forms";

$fieldTitleColor: #99AFC6;
$maxWidthColumn: 272px;

.title {
  font-weight: 600;
  font-size: 1.571rem;
  color: $darkBlueColor;
  margin-top: 27px;
  margin-bottom: 5px;
}

.form {
  @include tableWrapper;
  overflow: visible;

  .columns {
    display: flex;

    .column {
      flex: 1 1 auto;
      margin-right: 50px;
      max-width: $maxWidthColumn;
      width: 100%;
      flex-shrink: 0;
      &.single {
        max-width: none;
        margin-right: 0;
        input {
          max-width: $maxWidthColumn;
        }
      }

      .field {
        position: relative;
        display: block;
        margin-top: 15px;
        &:first-of-type {
          margin-top: 0;
        }
        &.fieldError {
          input {
            border: 1px solid $errColor;
            background: url("../../assets/imgs/err__icon.svg") no-repeat 95% 50%;
          }
        }

        .fieldTitle {
          font-weight: 600;
          color: $fieldTitleColor;
          margin-bottom: 3px;
        }

        input {
          height: 35px;
          width: 100%;
          border-radius: 23.5px;
          background-color: $sideBarBackgroundColor;
          color: #000;
          font-weight: 500;
          padding: 5px 10px 5px 24px;
          border: 1px solid transparent;
          &::placeholder {
            color: $fieldTitleColor;
            font-weight: 400;
          }
        }

        .datepicker {
          > * {
            width: 100%;
          }
        }

        .fieldBlog {
          display: flex;
          align-items: center;

          .fieldErrorMessage {
            position: static;
            margin-left: 21px;
          }
        }

        .fieldErrorMessage {
          position: absolute;
          color: $errColor;
          font-size: .8rem;
          font-weight: 500;
          margin-top: 2px;
        }
      }
    }

    .img {
      width: 100%;
    }
  }

  .checkers {
    display: flex;
    align-items: center;
    padding-top: 19px;
  }

  button[type="submit"] {
    @include saveBtnStyles;
    margin-top: 36px;
  }
}
