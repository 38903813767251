@import "variables";
@import "mixins";

.editor {
  padding-top: 30px;

  .title {
    color: $darkBlueColor;
    font-size: 1.571rem;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .formWrapper {
    @include tableWrapper;

    .form {
      &.columnsForm {
        display: flex;
        flex-wrap: wrap;

        .bottom {
          width: 100%;
        }
      }

      .formColumn {
        width: calc(50% - 46px / 2);
        margin-left: 46px;
        &:first-of-type {
          margin-left: 0;
        }
      }

      .formField {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        &:first-of-type {
          margin-top: 0;
        }

        .formFieldField {
          color: #99AFC6;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .formFieldError {
          position: absolute;
          color: $errColor;
          bottom: -15px;
          left: 0;
          font-size: .8em;
          letter-spacing: .05em;
          font-weight: 600;
        }

        input {
          background-color: #F5F7F8;
          border-radius: 23.5px;
          height: 35px;
          width: 100%;
          padding: 0 24px;
          color: #000000;
          font-weight: 500;
          &::placeholder {
            color: #99AFC6;
            font-weight: 400;
          }
        }

        .formFieldShowPass {
          position: absolute;
          right: 17px;
          bottom: 12px;
          width: 12px;
          height: 12px;
          background-color: $darkColor;
          mask: url("../../assets/imgs/eye__icon.svg") no-repeat center;
          mask-size: cover;
          &.active {
            background-color: $darkBlueColor;
          }
        }

        .formFieldMaxWidth {
          max-width: 523px;
        }
      }

      .colorField {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;
        width: 266px;

        .colorFieldLabel {
          font-weight: 500;
        }
      }

      .bottom {
        display: flex;
        padding-top: 40px;

        .formBtn {
          background-color: $yellowColor;
          border-radius: 18.5px;
          height: 37px;
          min-width: 127px;
          padding: 5px 10px;
          text-transform: uppercase;
          font-size: 1.143rem;
          font-weight: 500;
          margin-left: 14px;
          &:first-of-type {
            margin-left: 0;
          }
          &.cancel {
            background-color: $blueColor;
            color: #fff;
          }
          &.delete {
            background-color: $errColor;
            color: #fff;
            margin-left: auto;
          }
        }
      }
    }
  }
}
