@import "../Tabs.module";

.table {
  .tableItem {
    width: calc(100% / 5);
    text-align: center;
    &:first-child {
      text-align: left;
    }

    &.tableLogo {
      display: flex;
      justify-content: center;

      .logo {
        border: 1px solid $darkColor;
      }
    }

    &.color {
      text-align: center;
      padding-right: 50px;
    }
  }

  .tableColor {
    width: calc(100% / 5);
    display: flex;
    align-items: center;
    justify-content: center;

    :not(:first-child) {
      margin-left: -11px;
    }

    .tableColorItem {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 1px solid #fff;
    }
  }
}
