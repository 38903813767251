@import "./variables";

.notAuth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: $blueColor;

  .notAuthLogo {
    margin-bottom: 130px;
    user-select: none;

    img {
      max-width: 450px;
      height: auto;
    }
  }

  .notAuthForm {
    width: 100%;
    max-width: 371px;

    .notAuthFormField {
      margin-top: 16px;
      &:first-of-type {
        margin-top: 0;
      }

      input {
        background-color: #0356A7;
        width: 100%;
        height: 47px;
        border-radius: 23.5px;
        border: 0;
        padding: 5px 23px;
        font-size: 1.143em;
        color: #fff;
        font-style: italic;
        &::placeholder {
          font-size: 1.143rem;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .notAuthFormSubmit {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $yellowColor;
      height: 37px;
      border-radius: 18.5px;
      width: 100%;
      max-width: 171px;
      text-transform: uppercase;
      font-size: 1.143em;
      padding-top: 1px;
      margin: 26px auto 0;
      &.link {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
      }
    }

    .notAuthFormError {
      color: $errColor;
      font-size: .8em;
      letter-spacing: .05em;
      font-weight: 600;
      margin-top: 7px;
    }
  }

  .notAuthLink {
    display: inline-block;
    margin-top: 7px;
    color: #fff;
    font-size: .9em;
    font-style: italic;
    &:hover {
      color: #fff;
    }
  }
}
