@import "../../assets/scss/variables";
@import "../../assets/scss/icons";
@import "../../assets/scss/mixins";

$arrowToggleWidth: 18px;
$menuItemColor: #99AFC6;
$menuItemContainerWidth: 86px;

.sidebar {
  position: absolute;
  left: -$sideBarWidth;
  top: $headerHeight;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: $sideBarWidth;
  height: calc(100vh - #{$headerHeight});
  padding: 27px 0 51px;
  background-color: $sideBarBackgroundColor;
  z-index: $sidebarZIndex;
  transition: left $generalAnimationDuration;
  &.blurred {
    @include useBlur;
  }
  &.open {
    left: 0;

    .sidebarToggle {
      background-color: $sideBarBackgroundColor;

      span {
        background-color: #8A8A8A;
        transform: rotate(0);
      }
    }
  }

  .sidebarTop {
    width: 100%;
    height: 100%;
    padding-bottom: 54px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CACEDA;
      outline: none;
      width: 6px;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f7f8fc;
    }
  }

  .sidebarBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7f8fc;
    padding: 5px 0 5px 5px;
    box-shadow: 2px 2px 242px rgba(0, 0, 0, .25);
  }

  .sidebarToggle {
    position: absolute;
    bottom: 184px;
    right: -$arrowToggleWidth;
    width: $arrowToggleWidth;
    height: 33px;
    background-color: $blueColor;
    border-radius: 0 3px 3px 0;

    span {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      mask-position: 45% 50%;
      background-color: #fff;
      transform: rotate(180deg);
    }
  }

  .sidebarMenu {
    height: 100%;
    width: 100%;
    margin-bottom: 15px;

    .sidebarMenuItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% / 8);
      transition: background-color $generalAnimationDuration;
      &::before {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        margin: auto;
        width: $menuItemContainerWidth;
        background-color: #9F9F9F;
      }
      &:last-of-type {

      }
      &:hover {
        background-color: #fff;

        .sidebarMenuItemContainer {
          &::before {
            opacity: .3;
          }
        }
      }
      &.sidebarMenuItemActive {
        background-color: #fff;

        .sidebarMenuItemContainer {
          &::before {
            opacity: 1;
          }
        }
      }

      .sidebarMenuItemContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: $menuItemContainerWidth;
        &::before {
          display: block;
          content: '';
          position: absolute;
          left: calc((#{$sideBarWidth} - #{$menuItemContainerWidth}) / 2 * -1);
          top: 0;
          bottom: 0;
          width: 2px;
          background-color: $blueColor;
          border-radius: 0 1px 1px 0;
          opacity: 0;
          transition: opacity $generalAnimationDuration;
        }
      }

      .sidebarMenuItemIcon {
        width: 46px;
        height: 46px;
        background-color: $menuItemColor;
        width: 37px;
        height: 35px;
        mask-size: 100% 100%;
      }

      .sidebarMenuItemName {
        font-weight: 500;
        color: $menuItemColor;
        margin-top: 8px;
        text-align: center;
        width: 103px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .sidebarReportBtn {
    flex-shrink: 0;
    width: 92px;
    height: 92px;
    background-size: contain !important;
    box-shadow: 2px 2px 20px -11px rgba(0, 0, 0, .75);
    border-radius: 18.5px;
    transition: box-shadow $generalAnimationDuration;
    will-change: box-shadow;
    &:hover {
      box-shadow: 2px 2px 20px -8px rgba(0, 0, 0, .75);
    }

    .sidebarReportLogo {
      max-width: 56px;
    }

    .sidebarReportText {
      color: #444444;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
