@import "../../../../assets/scss/variables";

.modal {
  padding: 16px;
  border-radius: 12px;

  .content {
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 32px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .button {
      width: 100%;
      color: #fff;
      background-color: $blueColor;
      border-radius: 20px;
      height: 44px;
      font-size: 1.143rem;
      font-weight: 500;
      &.no {
        background-color: #fff;
        color: #F04438;
        border: 1px solid #F04438; 
      }
    }
  }
}