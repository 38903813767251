@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/tooltip";

$columnHeight: 41px;

.table {
  position: relative;
  @include tableWrapper;
  padding: 20px $tableRightPadding 65px 30px;
  min-height: 636px;

  .tableContent {
    background-color: #FFFDF5;
  }

  .rowWrapper {
    border-bottom: 1px solid $grayColor;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.header {
      padding: 10px 0;
      background-color: #E6F6FF;

      .headerName {
        color: #000000;
        line-height: 16px;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: column;
      }
    }
    &.total {
      background-color: #E6F6FF;
      border-radius: 3px;
      height: 42px;
      .column {
        font-size: .857rem;
        &:first-of-type {
          padding-left: 25px;
        }
      }
    }

    &.needs {
      background-color: #efecec !important;
    }

    &.value {
      background-color: #FFFDF5;
      &:last-of-type {
        .column {
          border-bottom: 0;
        }
      }
      &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        background-color: #F5F7F8;
        .column {
          border-bottom: 0;
        }
      }

      .column {
        height: $columnHeight;
        font-size: .857rem;
        &:first-of-type {
          display: flex;
          font-weight: 600;
          color: #0356A7 !important; 
          align-items: center;
          justify-content: space-between;

          .label {
            margin-right: 10px;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &.rowExpand {
      flex-wrap: wrap;
    }
  }

  .column {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $tablesColor;
    width: calc((100% - 182px) / 13);
    flex-shrink: 0;
    padding: 0 10px;
    &:first-of-type {
      width: 182px;
      justify-content: left;
    }

    .userMail {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #b2b2b2;
    }

    .blackText {
      color: #000000;
    }

    .user {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 17px;
      height: calc(#{$columnHeight} - 7px);
      width: calc(#{$columnHeight} - 7px);
      background-color: #D1D1D1;
      margin: auto;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E7F6FF;
      user-select: none;
      cursor: default;
      &.userAlign {
        left: 0;
        right: 0;
        margin: auto;
      }

      span {
        color: #656565;
        font-weight: 200;
        text-transform: uppercase;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .userTooltip {
    .userTooltipImg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .userTooltipInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 7px;
      &:first-of-type {
        margin-top: 0;
      }

      span:first-of-type {
        font-weight: 600;
      }

      span:last-of-type {
        padding: 3px 0;
      }
    }
  }

  .expand {
    width: 14px;
    height: 14px;
    background-color: $darkColor;
    mask: url("../../../assets/imgs/arrow_down.svg") no-repeat center center;
    mask-size: contain;
    transition: all $generalAnimationDuration;
    margin: -3px 7px 0 0;
    will-change: margin-top, transform;
    &.active {
      transform: rotate3d(1, 0, 0, 180deg);
      margin-top: 3px;
    }
  }

  .expandSection {
    width: 100%;
    height: 0;
    overflow: hidden;
    &.active {
      height: auto;
      padding: 5px 0;
    }
  }

  .positiveDiff {
    color: #30C250;
  }
  
  .negativeDiff {
    font-weight: bold;
    font-size: 13px;
    color: #D34539;
  };

  .weekText {
    color: rgba(68, 68, 68, 0.7);
    font-size: 12px;
  }
}
