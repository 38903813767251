.icon {
  width: 16px;
  height: 16px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  //background-color: var(--title-color);
  //background-color: var(--desc-color);
  background-color: #9BA1AD;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
}

.iconSvgDashboard {
  mask: url("../imgs/dashboard_icon.svg") no-repeat center;
}
.iconSvgAnalytics {
  mask: url("../imgs/analytics_icon.svg") no-repeat center;
}
.iconSvgGoalSettings {
  mask: url("../imgs/goal_setting_icon.svg") no-repeat center;
}
.iconSvgGoalTracker {
  mask: url("../imgs/goal-tracker-icon.svg") no-repeat center;
}
.iconSvgCompanyProjection {
  mask: url("../imgs/company-projection-icon.svg") no-repeat center;
}
.iconSvgRating {
  mask: url("../imgs/rating_icon.svg") no-repeat center;
}
.iconSvgAdmin {
  mask: url("../imgs/admin_icon.svg") no-repeat center;
}
.iconSvgSettings {
  mask: url("../imgs/settings_icon.svg") no-repeat center;
}
.iconSvgArrowLeft {
  mask: url("../imgs/arrow_left_icon.svg") no-repeat center;
}
.iconSvgArrowRightOnRectangle {
  mask: url("../imgs/arrow-right-on-rectangle.svg") no-repeat center;
}
.iconSvgDelete {
  mask: url("../imgs/delete__icon.svg") no-repeat center;
}
.iconSvgEdit {
  mask-image: url("../imgs/edit__icon.svg");
}
.iconSvgCancel {
  mask-image: url("../imgs/cancel__icon.svg");
}
.iconSvgSave {
  mask-image: url("../imgs/save__icon.svg");
}
