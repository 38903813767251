@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/tooltip";

.rating {
  padding-top: 30px;

  .title {
    color: $darkBlueColor;
    font-size: 1.571rem;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .table {
    @include tableWrapper;
  }

  .row, .rowHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &.rowHeader {
      .column {
        color: #656565;
        font-weight: 300;
        font-size: 1rem;
        border-bottom: 2px solid #DDDDDD;
        padding: 3px 10px;
        height: auto;
        &:last-of-type {
          padding-right: 49px;
        }
      }
    }
    &:last-of-type {
      .column {
        border-bottom: 0;
      }
    }
    &.active {
      .column {
        border-bottom: 2px solid $yellowColor;
      }

      .hiddenRow {
        max-height: 250px;
        opacity: 1;
      }
    }

    .column {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc((100% - 8%) / 7);
      font-size: 1.143rem;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 43px;
      border-bottom: 1px solid #DDDDDD;
      padding: 3px 10px;
      &:first-of-type {
        justify-content: flex-start;
      }
      &.last {
        justify-content: flex-end;
      }
      &.biggest {
        width: 17%;
      }
    }

    .rate {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 4px;
      background-color: #8BC24A;
      padding: 4px;

      .rateStar {
        display: block;
        width: 8px;
        height: 8px;
        mask: url("../../../assets/imgs/star__icon.svg") no-repeat center;
        mask-size: cover;
        background-color: #FF8E01;
        &.active {
          background-color: #fff;
        }
      }
    }

    .expandBtn {
      width: 14px;
      height: 14px;
      background-color: $tablesColor;
      mask: url("../../../assets/imgs/arrow_down.svg") no-repeat center center;
      mask-size: contain;
      transition: all $generalAnimationDuration;
      margin-left: 20px;
      &.active {
        transform: rotate3d(1, 0, 0, 180deg);
        mask-position: 50% 25%;
      }
    }

    .hiddenRow {
      display: flex;
      flex-wrap: wrap;
      max-height: 0;
      overflow: hidden;
      overflow-y: auto;
      opacity: 0;
      width: 100%;
      transition: max-height $generalAnimationDuration, opacity $generalAnimationDurationLongest;
      will-change: max-height, opacity;
      background-color: #FFFDF5;
      @include hideTheScrollbar;

      .hiddenRowColumn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% / 4);
        padding: 10px 20px;
        border-left: 1px solid #DDDDDD;
        &:nth-child(4n + 1) {
          border-left: 0;
        }
        &:nth-child(n + 5) {
          border-top: 1px solid #DDDDDD;
        }

        .hiddenRowColumnName {
          font-size: 1.1rem;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
    }
  }

  .noData {
    padding: 100px 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    opacity: .5;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}
