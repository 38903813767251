.picker--custom {
  &.empty {
    .react-datepicker__close-icon {
      display: none;
    }
  }

  .react-datepicker__close-icon {
    padding: 0;
    &::after {
      background-color: transparent;
      color: #444444;
      font-size: 20px;
      height: 10px;
      padding: 0;
      position: absolute;
      right: 6px;
      top: 0;
      bottom: 0;
      margin: auto;
      line-height: 7px;
    }
  }
}
